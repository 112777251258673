<template>
  <div id="checkout">
    <section class="section head">
      <div class="container">
        <h1>
          CHECKOUT
        </h1>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <div class="md-layout accordion-list">
          <div
            class="md-layout-item md-size-100 item licensing-terms"
            :class="{ open: step == 1 }"
          >
            <div class="item-header">
              <div class="number" :class="{ done: step > 1 }">
                <span>1</span>
                <md-icon>check</md-icon>
              </div>

              <h4>REVIEW AND ACKNOWLEDGE SONGFILE® LICENSING TERMS</h4>
            </div>

            <div class="item-content">
              <p>
                Please review the sample Songfile® license below. If your order
                includes songs for which HFA can issue a Songfile® license, you
                will receive a copy of the license. The sample license below
                sets forth the licensing terms that will govern the license
                requested by you. In order to complete your licensing request,
                you must acknowledge that you have reviewed and understand the
                terms of the Songfile® license by clicking "ACCEPT" below the
                sample license. The license will not be valid until you have
                provided all required information and payment in full has been
                made. The terms of the license will be governed by the final
                form available to you upon completion of the transaction.
              </p>
              <p>
                Your order may include the transmission of a Notice of Intention
                (NOI). If an NOI is to be transmitted on your behalf, the sample
                Songfile® license terms are separate and distinct from the terms
                of the license obtained from the transmission of an NOI. For
                more details about the terms of the statutory license, please
                visit the
                <a
                  href="https://copyright.gov/title17/92chap1.html#115"
                  target="_blank"
                >
                  Copyright Office</a
                >. Additional information can be obtained from the Copyright
                Circulars
                <a
                  href="https://copyright.gov/circs/circ73a.pdf"
                  target="_blank"
                >
                  73A</a
                >
                and
                <a
                  href="https://copyright.gov/circs/circ73b.pdf"
                  target="_blank"
                >
                  73B</a
                >. By clicking “ACCEPT” below, you acknowledge and agree that
                you have reviewed and understand the terms of the statutory
                license.
              </p>

              <div class="terms" id="licensing-terms">
                <LicenseTerms class="no-pdf" />
              </div>

              <div class="item-footer md-layout">
                <div class="md-layout-item md-size-25 md-small-size-100">
                  <md-button
                    @click="step = 2"
                    :disabled="!scrolledTerms"
                    class="md-primary md-raised large-width"
                  >
                    Accept
                  </md-button>
                </div>

                <span
                  class="md-layout-item md-size-75 md-small-size-100"
                  style="text-align: left;"
                >
                  The terms of the Songfile® license will be available to
                  download once your license has cleared. If your order requires
                  an NOI, a PDF copy will be available to you in the My Orders
                  tab soon after the NOI is transmitted.
                </span>
              </div>
            </div>
          </div>

          <div
            class="md-layout-item md-size-100 item licensee-info"
            :class="{ open: step == 2 }"
          >
            <div class="item-header">
              <div class="number" :class="{ done: step > 2 }">
                <span>2</span>
                <md-icon>check</md-icon>
              </div>

              <h4>REVIEW LICENSEE INFORMATION</h4>
            </div>

            <div class="item-content">
              <p>
                Please edit the information as necessary. The
                <strong>Licensee Name</strong> should be the label or
                organization (or individual) who will be distributing the
                recordings.
              </p>

              <div class="table md-layout">
                <div
                  class="column md-layout-item md-size-50 md-small-size-100 md-layout"
                >
                  <div class="md-layout-item md-size-40 label">
                    Licensee Name:
                  </div>
                  <div class="md-layout-item md-size-60 name">
                    {{ user.licensee_name }}
                  </div>

                  <div class="md-layout-item md-size-40 label">
                    Street Address 1:
                  </div>
                  <div class="md-layout-item md-size-60 name">
                    {{ user.street_address }}
                  </div>

                  <div class="md-layout-item md-size-40 label">
                    Street Address 2:
                  </div>
                  <div class="md-layout-item md-size-60 name">
                    {{ user.street_address_two }}
                  </div>

                  <div class="md-layout-item md-size-40 label">
                    Phone Number:
                  </div>
                  <div class="md-layout-item md-size-60 name">
                    {{ user.phone_number }}
                  </div>
                </div>

                <div
                  class="column md-layout-item md-size-50 md-small-size-100 md-layout"
                >
                  <div class="md-layout-item md-size-40 label">Country:</div>
                  <div class="md-layout-item md-size-60 name">
                    {{ user.country }}
                  </div>

                  <div class="md-layout-item md-size-40 label">City:</div>
                  <div class="md-layout-item md-size-60 name">
                    {{ user.city }}
                  </div>

                  <div class="md-layout-item md-size-40 label">
                    State/Province:
                  </div>
                  <div class="md-layout-item md-size-60 name">
                    {{ user.state }}
                  </div>

                  <div class="md-layout-item md-size-40 label">
                    Zip/Postal Code:
                  </div>
                  <div class="md-layout-item md-size-60 name">
                    {{ user.zip_code }}
                  </div>
                </div>
              </div>

              <div class="item-footer">
                <md-button
                  class="md-raised md-primary"
                  @click="showAccountForm = true"
                  >Edit</md-button
                >
                <md-button class="md-raised md-primary" @click="step = 3">
                  Continue
                </md-button>
              </div>
            </div>
          </div>

          <div
            class="md-layout-item md-size-100 item review-items"
            :class="{ open: step == 3 }"
          >
            <div class="item-header">
              <div class="number" :class="{ done: step > 3 }">
                <span>3</span>
                <md-icon>check</md-icon>
              </div>

              <h4>REVIEW YOUR SELECTIONS</h4>
            </div>

            <div class="item-content">
              <md-table v-model="cart" class="cart-table" v-if="!isMobile">
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                  <md-table-cell md-label="Release" md-sort-by="release">
                    {{ item.release }}
                  </md-table-cell>

                  <md-table-cell md-label="Song Title">
                    {{ item.songTitle }}
                  </md-table-cell>

                  <md-table-cell md-label="Units" class="units">
                    {{ item.units }}
                  </md-table-cell>

                  <md-table-cell md-label="Config" class="config">
                    {{ item.config.substring(0, 4) }}
                  </md-table-cell>

                  <md-table-cell md-label="Song Code">
                    {{ item.songCode }}
                  </md-table-cell>

                  <md-table-cell md-label="Artist">
                    {{ item.licenseRequest.artists }}
                  </md-table-cell>

                  <md-table-cell md-label="Playtime">
                    {{ item.licenseRequest.minutes | numeral("00") }}:{{
                      item.licenseRequest.seconds | numeral("00")
                    }}
                  </md-table-cell>

                  <md-table-cell md-label="Royalty Rate">
                    {{ item.royaltyFee | numeral("$0,0.0000") }}
                  </md-table-cell>

                  <md-table-cell md-label="Royalty Amount">
                    <strong>{{
                      item.royaltyAmount | numeral("$0,0.00")
                    }}</strong>
                  </md-table-cell>

                  <md-table-cell md-label="Processing Fee">
                    <strong>{{
                      item.processingFee | numeral("$0,0.00")
                    }}</strong>
                  </md-table-cell>
                </md-table-row>
              </md-table>

              <div v-else class="mobile-cart">
                <div
                  class="md-layout"
                  v-for="(item, index) in cart"
                  :key="index"
                >
                  <div class="md-layout-item md-size-35 label">
                    Release
                  </div>
                  <div class="md-layout-item md-size-65">
                    {{ item.release }}
                  </div>

                  <div class="md-layout-item md-size-35 label">
                    Song Title
                  </div>
                  <div class="md-layout-item md-size-65">
                    {{ item.songTitle }}
                  </div>

                  <div class="md-layout-item md-size-35 label">
                    Units
                  </div>
                  <div class="md-layout-item md-size-65">
                    {{ item.units }}
                  </div>

                  <div class="md-layout-item md-size-35 label">
                    Config
                  </div>
                  <div class="md-layout-item md-size-65">
                    {{ item.config.substring(0, 4) }}
                  </div>

                  <div class="md-layout-item md-size-35 label">
                    Song Code
                  </div>
                  <div class="md-layout-item md-size-65">
                    {{ item.songCode }}
                  </div>

                  <div class="md-layout-item md-size-35 label">
                    Artist
                  </div>
                  <div class="md-layout-item md-size-65">
                    {{ item.licenseRequest.artists }}
                  </div>

                  <div class="md-layout-item md-size-35 label">
                    Playtime
                  </div>
                  <div class="md-layout-item md-size-65">
                    {{ item.licenseRequest.minutes | numeral("00") }}:{{
                      item.licenseRequest.seconds | numeral("00")
                    }}
                  </div>

                  <div class="md-layout-item md-size-35 label">
                    Royalty Rate
                  </div>
                  <div class="md-layout-item md-size-65">
                    {{ item.royaltyFee | numeral("$0,0.0000") }}
                  </div>

                  <div class="md-layout-item md-size-35 label">
                    Royalty Amount
                  </div>
                  <div class="md-layout-item md-size-65">
                    <strong>{{
                      item.royaltyAmount | numeral("$0,0.00")
                    }}</strong>
                  </div>

                  <div class="md-layout-item md-size-35 label">
                    Processing Fee
                  </div>
                  <div class="md-layout-item md-size-65">
                    <strong>{{
                      item.processingFee | numeral("$0,0.00")
                    }}</strong>
                  </div>
                </div>
              </div>

              <div class="table-footer">
                <div></div>
                <div class="total">
                  <div>
                    <router-link
                      target="_blank"
                      :to="{ name: 'faq' }"
                      class="help"
                    >
                      <md-icon>help</md-icon>
                    </router-link>
                    <div class="name">Royalty Amount Subtotal</div>
                    <div class="value">
                      {{ royaltyAmountSubtotal() | numeral("$0,0.00") }}
                    </div>
                  </div>
                  <div>
                    <router-link
                      target="_blank"
                      :to="{ name: 'faq' }"
                      class="help"
                    >
                      <md-icon>help</md-icon>
                    </router-link>
                    <div class="name">Processing Fees</div>
                    <div class="value">
                      {{ processingFeesTotal() | numeral("$0,0.00") }}
                    </div>
                  </div>
                  <div>
                    <div class="name">Total</div>
                    <div class="value">
                      {{ total() | numeral("$0,0.00") }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="item-footer">
                <md-button
                  class="md-raised md-primary"
                  :to="{ name: 'my-cart' }"
                >
                  Edit
                </md-button>
                <md-button
                  :disabled="validatingNoi"
                  class="md-raised md-primary"
                  @click="getToken()"
                >
                  <span v-show="validatingNoi" class="loading-text">
                    <md-progress-spinner
                      class="md-accent"
                      :md-diameter="15"
                      :md-stroke="2"
                      md-mode="indeterminate"
                    />
                    Loading
                  </span>
                  <span v-show="!validatingNoi">Continue</span>
                </md-button>
              </div>
            </div>
          </div>

          <div
            class="md-layout-item md-size-100 item license-info"
            :class="{ open: step == 4 }"
          >
            <div class="item-header">
              <div class="number" :class="{ done: step > 4 }">
                <span>4</span>
                <md-icon>check</md-icon>
              </div>

              <h4>PAYMENT</h4>
            </div>

            <div class="item-content">
              <p>
                Your total due is:
                <strong>{{ total() | numeral("$0,0.00") }}</strong>
              </p>

              <div id="credit-card-fields">
                <label class="hosted-field" for="card-number">
                  <span class="label">Card Number</span>
                  <div class="field" id="card-number"></div>
                </label>

                <label class="hosted-field" for="expiration-date">
                  <span class="label">Expiration Date (MM/YY)</span>
                  <div class="field" id="expiration-date"></div>
                </label>

                <label class="hosted-field" for="cvv">
                  <span class="label">CVV</span>
                  <div class="field" id="cvv"></div>
                </label>

                <label class="hosted-field" for="postal-code">
                  <span class="label">Postal Code</span>
                  <div class="field" id="postal-code"></div>
                </label>
              </div>

              <md-button
                class="md-raised md-primary"
                id="submit-button"
                :disabled="!loaded"
                @click="pay()"
              >
                <span v-show="!loaded">Loading</span>
                <span v-show="loaded">Submit Payment and Download</span>
              </md-button>
            </div>
          </div>
        </div>
      </div>
    </section>

    <md-dialog :md-active.sync="showAccountForm" @md-closed="closeDialog">
      <md-dialog-title>
        <span>YOUR PROFILE</span>

        <md-button
          class="md-raised md-dense md-icon-button close"
          @click="showAccountForm = false"
        >
          <md-icon>clear</md-icon>
        </md-button>
      </md-dialog-title>

      <md-dialog-content>
        <AccountForm @closeDialog="showAccountForm = false" checkout="true" />
      </md-dialog-content>
    </md-dialog>

    <div class="payment-overlay overlay" v-show="loadingPayment">
      Loading...
      <br />
      <br />
      <md-progress-spinner md-mode="indeterminate"></md-progress-spinner>
    </div>

    <md-dialog :md-active.sync="showNoiError" @md-closed="noiErrorClosed">
      <md-dialog-title>
        <span>Why was my song removed from my release?</span>

        <md-button
          class="md-raised md-dense md-icon-button close"
          @click="showNoiError = false"
        >
          <md-icon>clear</md-icon>
        </md-button>
      </md-dialog-title>

      <md-dialog-content>
        <div class="noi-help">
          <p>
            One or more of your songs are unable to be licensed at this time, we
            have removed them from your cart. Please review the following for
            more information.
          </p>
          <p>
            Distribution Date (Physical Product): You should enter the date you
            intend to begin selling or giving away to the public the product
            that includes the composition. The date must be five (5) or more
            days after the date of your order.
          </p>
          <p>
            Distribution Date (Digital Products): You should enter the date you
            intend to begin making available to the public digital copies of the
            sound recording that includes the composition. The date must be no
            more than twenty-four (24) days prior to the date of your order but
            can be any time after the date of the order.
          </p>
          <p>
            Manufacture Date (Physical Product): You should enter the date you
            have created copies of the product that includes the composition or
            the date you intend to begin making copies of the product that
            includes the composition. The date must be no more than twenty-four
            (24) days prior to the date of your order but can be any time after
            the date of the order.
          </p>
          <p>
            Record Company: If you intend to distribute, by way of permanent
            download or as a ringtone, copies of the sound recording embodying
            the composition, prior to completing your order please indicate in
            your Account Profile that you are a Record Company. A record company
            invests in, produces, and markets sound recordings of musical works,
            and distributes such recordings for remuneration through multiple
            sales channels.
          </p>
        </div>
      </md-dialog-content>
    </md-dialog>
  </div>
</template>

<script>
import AccountForm from "@/components/AccountForm.vue";
import LicenseTerms from "@/components/LicenseTerms.vue";
import { mapGetters, mapState, mapActions } from "vuex";
import axios from "axios";

export default {
  name: "Checkout",
  components: {
    AccountForm,
    LicenseTerms
  },
  metaInfo: function() {
    return {
      title: " - Checkout",
      meta: [
        {
          name: "description",
          content: "Check your information and proceed to checkout."
        }
      ],
      link: [
        {
          vmid: "canonical",
          rel: "canonical",
          href: this.$store.getters["canonical"]()
        }
      ]
    };
  },
  data: function() {
    return {
      showAccountForm: false,
      step: 1,
      loaded: false,
      loadingPayment: false,
      scrolledTerms: false,
      showNoiError: false,
      validatingNoi: false
    };
  },
  computed: {
    ...mapState({
      status: state => state.release.status
    }),
    cart: function() {
      return this.$store.getters["release/getAllCartItems"];
    },
    user: function() {
      return this.$store.state.auth.user;
    },
    isMobile: function() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },
    isCartEmpty: function() {
      return this.$store.getters["release/getAllCartItems"].length == 0;
    }
  },
  watch: {
    status: function(newValue) {
      if (newValue == "idle" && this.isCartEmpty) {
        this.$toastr("error", "Your cart is empty.");
        this.$router.push({ name: "new-release" });
      }
    }
  },
  methods: {
    ...mapActions("payment", ["createCreditCardFields", "submitPayment"]),
    ...mapGetters("release", [
      "royaltyAmountSubtotal",
      "processingFeesTotal",
      "total"
    ]),
    closeDialog: function() {
      this.showAccountForm = false;
    },
    addLoading: function() {
      this.loadingPayment = true;
      document.getElementsByTagName("html")[0].classList.add("has-overlay");
    },
    removeLoading: function() {
      this.loadingPayment = false;
      document.getElementsByTagName("html")[0].classList.remove("has-overlay");
    },
    noiErrorClosed: function() {
      this.showNoiError = false;
      this.$router.push({ name: "my-cart" });
    },
    getToken: async function() {
      try {
        this.validatingNoi = true;
        if (!(await this.$store.dispatch("release/isNoiValid"))) {
          this.showNoiError = true;
          return false;
        }
      } catch (error) {
        this.$toastr(
          "error",
          "We couldn't validate your cart at the moment. Please try again later"
        );
        return false;
      } finally {
        this.validatingNoi = false;
      }

      this.step = 4;

      this.addLoading();

      try {
        await this.createCreditCardFields();
        this.loaded = true;
      } catch (err) {
        this.$toastr("add", {
          msg: err,
          timeout: 60000,
          type: "error"
        });
      }

      this.removeLoading();
    },
    pay: async function() {
      this.addLoading();

      try {
        const transactionId = await this.submitPayment({
          fee: this.total(),
          email: this.user.email
        });
        this.removeLoading();

        this.$router.push({
          name: "receipt",
          params: { order: transactionId }
        });
      } catch (err) {
        this.removeLoading();
        this.$toastr("add", {
          msg: err,
          timeout: 60000,
          type: "error"
        });
      }
    }
  },
  mounted: function() {
    const terms = document.getElementById("licensing-terms");
    terms.addEventListener("scroll", () => {
      if (
        Math.ceil(terms.scrollHeight - terms.scrollTop) <=
        terms.clientHeight + 100
      ) {
        this.scrolledTerms = true;
      }
    });
  }
};
</script>

<style lang="scss">
.noi-help {
  padding: 20px 0 0;

  p {
    color: #000;
    font-size: 1.2em;
    font-weight: 500;
  }
}
#checkout {
  .payment-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    flex-direction: column;
    color: #fff;
    font-size: 2em;
  }
  .accordion-list {
    .item {
      &.licensing-terms {
        .item-footer {
          .md-button {
            display: block;
            margin: 0 auto 10px;
          }
          span {
            text-align: center;
          }
        }
      }
      &.licensee-info,
      &.review-items {
        .item-footer {
          justify-content: center;

          @media all and (min-width: 990px) {
            justify-content: flex-start;
          }
        }
      }
      &.licensee-info {
        .table {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;

          .column {
            display: flex;
            flex-wrap: wrap;
            font-size: 13px;

            > div {
              padding: 7px 0;
            }

            .label {
              font-weight: bold;
              font-family: $font-Gilroy-extrabold;
            }

            @media all and (min-width: 990px) {
              font-size: 16px;

              > div {
                padding: 10px 0;
              }
            }
          }
        }
      }
      &.review-items {
        .mobile-cart {
          .md-layout {
            padding-bottom: 10px;
            margin-bottom: 10px;
            border-bottom: 1px solid #efefef;

            &:last-child {
              border: 0;
            }
          }
          .md-layout-item {
            padding: 7.5px 0;

            &.label {
              font-family: $font-Gilroy-extrabold;
            }
          }
        }
      }

      #credit-card-fields {
        position: relative;
        padding: 20px;
        border: 1px solid #999;
        border-radius: 3px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
        column-gap: 10px;
        row-gap: 20px;

        label.hosted-field {
          &.blur {
            filter: blur(3px);
          }

          &[for="card-number"] {
            grid-column: 1 / span 3;
          }

          .label {
            font-weight: bold;
          }
          .field {
            border: 1px solid #999;
            height: 60px;
            padding: 0 10px;
          }
        }
      }
      #credit-card-fields + .md-button {
        width: 250px;
        margin-top: 20px;
        margin-left: 0;
      }
    }
  }
}
</style>
